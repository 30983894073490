import api from 'data/axios/react-query-api';
import {
  type TowerEntity,
  type TowerDetailEntity,
  type TowerPositionContext,
  type TowersDomainTypeEntity,
  type ValidateProgressFormValues,
  type ValidationState,
  type TowerBoxDetailEntity,
} from 'data/types/entities';
import { type ListEntity } from 'data/utils/types';
import { type MoveKidsValidator } from 'data/validators';

import { type TowerMetadataEntity } from 'data/types/entities/towers/tower-metadata.entity';
import { type TowersQuery } from 'data/validators/query-params/towers.query';
import { type ExpandedTowerEntity } from 'data/types/entities/towers/expanded-tower.entity';
import { type TowerValidator } from 'data/validators/body/tower/tower.validator';
import { type TowerBoxValidator } from 'data/validators/body/tower-box/tower-box.validator';
import { type TowerDimensionTypeValidator } from 'data/validators/body/tower/tower-dimension-type.validator';
import { type TowerDimensionValidator } from 'data/validators/body/tower/tower-dimension.validator';
import { type PositionValidator } from 'data/validators/body/learning-content/position.validator';
import { type TowerInfoEntity } from 'data/types/entities/towers/tower-info.entity';

export const fetchTowers = (params?: TowersQuery): Promise<ListEntity<ExpandedTowerEntity>> =>
  api.get('/towers', { params });
export const fetchTowerPosition = (towerId: string): Promise<TowerPositionContext> =>
  api.get(`/tower/progress/towers/${towerId}/position`);

export const fetchTowerNext = (questionId: string, towerBoxId: string): Promise<TowerPositionContext> =>
  api.get(`/tower/progress/questions/${questionId}/towerBoxes/${towerBoxId}/next`);

export const validateTower = (values: ValidateProgressFormValues): Promise<ValidationState> =>
  api.post('/tower/progress/validate', values);

export const fetchTowersForDomainTypeAsKid = (domainTypeId: string): Promise<TowersDomainTypeEntity> =>
  api.get(`/domains/${domainTypeId}/towers`);

export const fetchTower = (towerId: string): Promise<TowerDetailEntity> => api.get(`/towers/${towerId}`);

export const fetchTowerInfo = (towerId: string) => api.get<TowerInfoEntity>(`/towers/${towerId}/info`);

export const pushKidsAndGroupsToTower = (towerId: string, body: MoveKidsValidator) =>
  api.post(`/towers/${towerId}/move`, body);

export const fetchTowerMetadata = (towerId: string) => api.get<TowerMetadataEntity>(`/towers/${towerId}/metadata`);

export const updateTower = (towerId: string, body: TowerValidator) =>
  api.patch<TowerDetailEntity>(`/towers/${towerId}`, body);

export const setTowerPicture = (towerId: string, body: FormData) =>
  api.patch<TowerEntity>(`/towers/${towerId}/picture`, body);

export const deleteTower = (towerId: string) => api.delete(`/towers/${towerId}`);

export const createTowerBox = (towerId: string, body: TowerBoxValidator) =>
  api.post<TowerDetailEntity>(`/towers/${towerId}/towerboxes`, body);

export const updateTowerBox = (towerId: string, towerBoxId: string, body: TowerBoxValidator) =>
  api.patch<TowerBoxDetailEntity>(`/towers/${towerId}/towerboxes/${towerBoxId}`, body);

export const createTowerDimension = (towerId: string, body: TowerDimensionTypeValidator) =>
  api.post<TowerDetailEntity>(`/towers/${towerId}/dimensions`, body);

export const updateTowerDimension = (towerId: string, towerDimensionId: string, body: TowerDimensionValidator) =>
  api.patch<TowerDetailEntity>(`/towers/${towerId}/dimensions/${towerDimensionId}`, body);

export const deleteTowerDimension = (towerId: string, towerDimensionId: string) =>
  api.delete<TowerDetailEntity>(`/towers/${towerId}/dimensions/${towerDimensionId}`);

export const updateTowerRowPosition = (towerId: string, towerRowId: string, body: PositionValidator) =>
  api.patch<TowerDetailEntity>(`/towers/${towerId}/dimensions/rows/${towerRowId}/positions`, body);

export const updateTowerColumnPosition = (towerId: string, towerColumnId: string, body: PositionValidator) =>
  api.patch<TowerDetailEntity>(`/towers/${towerId}/dimensions/columns/${towerColumnId}/positions`, body);
