import React from 'react';
import { HeadItem, icons, Sidebar, SubItem } from '@coach/ui';

import {
  bookLicensesPath,
  bookPackagesPath,
  generatorPath,
  groupsPath,
  membersPaths,
  questionBoxesPath,
  questionClipboardPath,
  savedConfigPath,
  wordListPath,
} from 'app/constants/url/admin';
import {
  booksPath,
  digitoolsPath,
  documentsPath,
  exercisesPath,
  fieldsPath,
  gradesPath,
  learningCulturesManagePath,
  learningCulturesPath,
  learningCulturesViewPath,
  learningLinesPath,
  tasksPath,
  videosPath,
} from 'app/constants/url/shared';

import { useTranslation } from 'react-i18next';
import ConnectIcon from '@icons/coach/Connect';
import AssignmentIcon from '@mui/icons-material/Assignment';
import SchoolIcon from '@mui/icons-material/SchoolOutlined';
import BarChartIcon from '@icons/coach/BarChart';

export const AdminSidebar = () => {
  const { t } = useTranslation();

  return (
    <Sidebar className="overflow-x-hidden">
      <HeadItem name={t('generator')} icon={icons.dashboard} data-name="sidebar-generator">
        {/*  @ts-expect-error TS(2739): Type '{ children: any; to: string; "data-sentry-br... Remove this comment to see the full error message */}
        <SubItem to={generatorPath} data-name="sidebar-generator-generator">
          {t('generator')}
        </SubItem>
        {/*  @ts-expect-error TS(2739): Type '{ children: any; to: string; "data-sentry-br... Remove this comment to see the full error message */}
        <SubItem to={savedConfigPath} data-name="sidebar-generator-configs">
          {t('savedConfigs')}
        </SubItem>
        {/*  @ts-expect-error TS(2739): Type '{ children: any; to: string; "data-sentry-br... Remove this comment to see the full error message */}
        <SubItem to={questionClipboardPath} data-name="sidebar-generator-clipboard">
          {t('questionClipboard')}
        </SubItem>
      </HeadItem>

      <HeadItem name={t('fields')} to={fieldsPath} icon={icons.school} data-name="sidebar-fields-and-domains" />
      <HeadItem
        name={t('grades')}
        to={gradesPath}
        icon={<BarChartIcon fontSize="small" />}
        data-name="sidebar-grades"
      />

      <HeadItem
        name={t('learningCultures')}
        to={learningCulturesPath}
        icon={icons.book}
        data-name="sidebar-learning-cultures"
      >
        <SubItem to={learningCulturesManagePath} icon={icons.book} data-name="sidebar-learning-cultures-manage">
          {t('manage')}
        </SubItem>
        <SubItem to={learningCulturesViewPath} icon={icons.eye} data-name="sidebar-learning-cultures-view">
          {t('view')}
        </SubItem>
      </HeadItem>

      <HeadItem name={t('tasks')} to={tasksPath.index} icon={icons.calendarChecked} data-name="sidebar-actions" />
      <HeadItem
        name={t('learningLines')}
        to={learningLinesPath}
        icon={<ConnectIcon fontSize="small" />}
        data-name="sidebar-learning-lines"
      />
      <HeadItem
        name={t('exercises')}
        to={exercisesPath}
        icon={<AssignmentIcon fontSize="small" />}
        data-name="sidebar-exercises"
      />
      <HeadItem
        name={t('questionBoxes')}
        to={questionBoxesPath}
        icon={icons.boxes}
        data-name="sidebar-question-boxes"
      />
      <HeadItem name={t('catalogue')} to={booksPath} icon={icons.catalogue} data-name="sidebar-library-catalogue" />
      <HeadItem
        name={t('bookPackages')}
        to={bookPackagesPath}
        icon={icons.package}
        data-name="sidebar-library-book-packages"
      />
      <HeadItem
        name={t('bookLicenses')}
        to={bookLicensesPath}
        icon={icons.license}
        data-name="sidebar-library-book-licenses"
      />
      <HeadItem name={t('digitools')} to={digitoolsPath} icon={icons.videoGames} data-name="sidebar-digitools" />
      <HeadItem name={t('videos')} to={videosPath} icon={icons.video} data-name="sidebar-library-videos" />

      <HeadItem name={t('members')} icon={icons.group} data-name="sidebar-members">
        <SubItem to={membersPaths.coaches} icon={<SchoolIcon />} data-name="sidebar-members-coaches">
          {t('coaches')}
        </SubItem>
        <SubItem to={membersPaths.children} icon={icons.child} data-name="sidebar-members-children">
          {t('children')}
        </SubItem>
        <SubItem to={membersPaths.admins} icon={icons.security} data-name="sidebar-members-admins">
          {t('admins')}
        </SubItem>
        <SubItem to={membersPaths.unconfirmedUsers} icon={icons.group} data-name="sidebar-members-unconfirmed-users">
          {t('unconfirmedUsers')}
        </SubItem>
      </HeadItem>
      <HeadItem name={t('groups')} to={groupsPath} icon={icons.groups} data-name="sidebar-groups" />
      <HeadItem name={t('documents')} to={documentsPath} icon={icons.openFolder} data-name="sidebar-documents" />

      <HeadItem name={t('wordList')} to={wordListPath} data-name="sidebar-wordlist" />
    </Sidebar>
  );
};
