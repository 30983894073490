/* eslint-disable no-param-reassign */
import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import {
  type AnswerCountState,
  type JumpState,
  type MistakeEntity,
  type QuestionPosition,
  type ValidationState,
} from 'data/types';
import { type useValidateTower } from 'data/hooks/progress/tower';
import { type useValidateLearningLine } from 'data/hooks/progress/learningLines';
import { type useValidateActionAnswer } from 'data/hooks/queries/actions';
import { isFunction } from 'remeda';

export type ValidationMutation = ReturnType<
  typeof useValidateTower | typeof useValidateLearningLine | typeof useValidateActionAnswer
>;
interface ProgressStoreState {
  answerCountState: AnswerCountState;
  jumpState?: JumpState;
  validationState?: ValidationState;
  firstRendered: boolean;
  firstValidated: boolean;
  jump: number;
  mistakes?: MistakeEntity[];
  attempts: number;
  position?: QuestionPosition;
  nextPosition?: QuestionPosition;
  isFinished: boolean;
  isPositionLoaded: boolean;
  isPositionLoading: boolean;
  validationMutation?: ValidationMutation;
}

type Setter<T> = ((storeUnit: T) => T) | T;

export interface ProgressStoreActions {
  setAnswerCountState: (setter: Setter<AnswerCountState>) => void;
  setJumpState: (setter: Setter<JumpState | undefined>) => void;
  setValidationState: (setter: Setter<ValidationState | undefined>) => void;
  setMistakes: (setter: Setter<MistakeEntity[] | undefined>) => void;
  setJump: (setter: Setter<number>) => void;
  setFirstRendered: (setter: Setter<boolean>) => void;
  setFirstValidated: (setter: Setter<boolean>) => void;
  setAttempts: (setter: Setter<number>) => void;
  setPosition: (setter: Setter<QuestionPosition | undefined>) => void;
  setNextPosition: (setter: Setter<QuestionPosition | undefined>) => void;
  setIsFinished: (setter: Setter<boolean>) => void;
  setIsPositionLoaded: (isPositionLoaded: boolean) => void;
  setIsPositionLoading: (isPositionLoading: boolean) => void;
  setValidationMutation: (validationMutation: ValidationMutation) => void;
  resetValidationState: () => void;
  reset: () => void;
}

type ProgressStore = ProgressStoreState & { actions: ProgressStoreActions };

const initialState: ProgressStoreState = {
  answerCountState: {
    correctAnswerCount: 0,
    incorrectAnswerCount: 0,
    consecutiveCorrectCount: 0,
    consecutiveIncorrectCount: 0,
    qBoxCount: 1,
    firstTimeRightCount: 0,
    timeSpentLastQuestions: 0,
    questionBoxCorrectAnswerCount: 0,
    questionBoxIncorrectAnswerCount: 0,
  },
  jumpState: undefined,
  validationState: undefined,
  mistakes: undefined,
  jump: 0,
  firstRendered: false,
  firstValidated: false,
  attempts: 1,
  position: undefined,
  nextPosition: undefined,
  isFinished: false,
  isPositionLoaded: false,
  isPositionLoading: false,
  validationMutation: undefined,
};

export const useProgressStore = create<ProgressStore>()(
  persist(
    immer((set) => ({
      ...initialState,
      actions: {
        setAnswerCountState: (setter) =>
          set((draft: ProgressStoreState) => {
            draft.answerCountState = isFunction(setter) ? setter(draft.answerCountState) : setter;
          }),
        setJumpState: (setter) =>
          set((draft: ProgressStoreState) => {
            draft.jumpState = isFunction(setter) ? setter(draft.jumpState) : setter;
          }),
        setValidationState: (setter) =>
          set((draft: ProgressStoreState) => {
            draft.validationState = isFunction(setter) ? setter(draft.validationState) : setter;
          }),
        setMistakes: (setter) =>
          set((draft: ProgressStoreState) => {
            draft.mistakes = isFunction(setter) ? setter(draft.mistakes) : setter;
          }),
        setJump: (setter) =>
          set((draft: ProgressStoreState) => {
            draft.jump = isFunction(setter) ? setter(draft.jump) : setter;
          }),
        setFirstRendered: (setter) =>
          set((draft: ProgressStoreState) => {
            draft.firstRendered = isFunction(setter) ? setter(draft.firstRendered) : setter;
          }),
        setFirstValidated: (setter) =>
          set((draft: ProgressStoreState) => {
            draft.firstValidated = isFunction(setter) ? setter(draft.firstValidated) : setter;
          }),
        setAttempts: (setter) =>
          set((draft: ProgressStoreState) => {
            draft.attempts = isFunction(setter) ? setter(draft.attempts) : setter;
          }),
        setPosition: (setter) =>
          set((draft: ProgressStoreState) => {
            draft.position = isFunction(setter) ? setter(draft.position) : setter;
          }),
        setNextPosition: (setter) =>
          set((draft: ProgressStoreState) => {
            draft.nextPosition = isFunction(setter) ? setter(draft.nextPosition) : setter;
          }),
        setIsFinished: (setter) =>
          set((draft: ProgressStoreState) => {
            draft.isFinished = isFunction(setter) ? setter(draft.isFinished) : setter;
          }),
        setIsPositionLoaded: (isPositionLoaded: boolean) => set(() => ({ isPositionLoaded })),
        setIsPositionLoading: (isPositionLoading: boolean) => set(() => ({ isPositionLoading })),
        setValidationMutation: (validationMutation: ValidationMutation) => set(() => ({ validationMutation })),
        resetValidationState: () => set(() => ({ validationState: undefined })),
        reset: () =>
          set((store) => ({
            ...initialState,
            answerCountState: store.answerCountState,
            jumpState: store.jumpState,
          })),
      },
    })),
    {
      name: 'state',
      storage: createJSONStorage(() => sessionStorage),
      partialize: (state) => ({ answerCountState: state.answerCountState, jumpState: state.jumpState }),
    },
  ),
);

export const getProgressStore = () => useProgressStore.getState();

export const useProgressStoreActions = () => useProgressStore((store) => store.actions);

export const useAnswerCountState = () => useProgressStore((store) => store.answerCountState);
export const useJumpState = () => useProgressStore((store) => store.jumpState);
export const useValidationState = () => useProgressStore((store) => store.validationState);
export const useMistakes = () => useProgressStore((store) => store.mistakes);
export const useJump = () => useProgressStore((store) => store.jump);
export const useFirstRendered = () => useProgressStore((store) => store.firstRendered);
export const useFirstValidated = () => useProgressStore((store) => store.firstValidated);
export const useAttempts = () => useProgressStore((store) => store.attempts);
export const usePosition = () => useProgressStore((store) => store.position);
export const useNextPosition = () => useProgressStore((store) => store.nextPosition);
export const useIsFinished = () => useProgressStore((store) => store.isFinished);
export const useIsPositionLoaded = () => useProgressStore((store) => store.isPositionLoaded);
export const useIsPositionLoading = () => useProgressStore((store) => store.isPositionLoading);
export const useValidationMutation = () => useProgressStore((store) => store.validationMutation);
